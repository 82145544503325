/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const SearchIconDark = (props) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.8879 12.4147H13.1156L12.8419 12.1509C14.015 10.783 14.6211 8.91684 14.2887 6.93342C13.8293 4.21722 11.5612 2.04817 8.82382 1.71597C4.68846 1.2079 1.20812 4.6862 1.71649 8.81913C2.04888 11.5549 4.2192 13.8216 6.937 14.2808C8.92158 14.613 10.7888 14.0073 12.1575 12.8348L12.4215 13.1084V13.8803L16.5764 18.0327C16.9772 18.4333 17.6322 18.4333 18.033 18.0327C18.4339 17.6321 18.4339 16.9775 18.033 16.5769L13.8879 12.4147ZM8.02216 12.4147C5.58788 12.4147 3.62285 10.4508 3.62285 8.01795C3.62285 5.58509 5.58788 3.62122 8.02216 3.62122C10.4564 3.62122 12.4215 5.58509 12.4215 8.01795C12.4215 10.4508 10.4564 12.4147 8.02216 12.4147Z"
			fill="#190A28"
		/>
	</svg>
);

export default SearchIconDark;
